
// 当前前台是否为开发模式
const isDev = process.env.NODE_ENV === 'development1'
let merchantSiteUrl = isDev ? 'http://192.168.0.154:8091' : 'https://mtest.bjftyx.net/web'
let merchantUrl = isDev ? 'http://192.168.0.154' : 'https://mtest.bjftyx.net/merchant'
let merchantPortUrl = isDev ? 'http://192.168.0.154:8083' : 'https://mtest.bjftyx.net/merchant-api'
let merchantApiUrl = isDev ? 'http://192.168.0.154:8083' : 'https://mtest.bjftyx.net/merchant-api'
switch (process.env.VUE_APP_APP_HOST) {
  case 'development'://测试
    merchantSiteUrl = 'https://mtest.bjftyx.net/web'
    merchantUrl = 'https://mtest.bjftyx.net/merchant'
    merchantPortUrl = 'https://mtest.bjftyx.net/merchant-api'
    merchantApiUrl = 'https://mtest.bjftyx.net/merchant-api'
    break
  case 'production'://正式
    merchantSiteUrl = 'https://m.bjftyx.net/web'
    merchantUrl = 'https://m.bjftyx.net/merchant'
    merchantPortUrl = 'https://m.bjftyx.net/merchant-api'
    merchantApiUrl = 'https://m.bjftyx.net/merchant-api'
    break
}
export default {
  pageSize: 10,
  merchantSiteUrl: merchantSiteUrl,
  merchantUrl: merchantUrl,
  merchantPortUrl: merchantPortUrl,
  merchantApiUrl: merchantApiUrl,//文件上传
  isDev: true,
}


